import Vue from 'vue'
import Vuex from 'vuex'
const axios = require('axios')

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		asset: null,
		assets: null,
		channel: null,
		categories: null,
		category: null,
		downloading: null,
		loaded: false,
		password: null,
		rootCategory: null,
		type: null
	},
	getters: {
		asset: state => {
			return state.asset
		},
		assets: state => {
			return state.assets
		},
		categories: state => {
			return state.categories
		},
		category: state => {
			return state.category
		},
		channel: state => {
			return state.channel
		},
		downloading: state => {
			return state.downloading
		},
		loaded: state => {
			return state.loaded
		},
		password: state => {
			return state.password
		},
		rootCategory: state => {
			return state.rootCategory
		},
		type: state => {
			return state.type
		}
	},
	mutations: {
		asset: (state, data) => {
			state.asset = data
		},
		assets: (state, data) => {
			state.assets = data
		},
		channel: (state, data) => {
			state.channel = data
		},
		categories: (state, data) => {
			state.categories = data
		},
		category: (state, data) => {
			state.category = data
		},
		downloading: (state, data) => {
			state.downloading = data
		},
		loaded: (state, data) => {
			state.loaded = data
		},
		password: (state, data) => {
			state.password = data
		},
		rootCategory: (state, data) => {
			state.rootCategory = data
		},
		type: (state, data) => {
			state.type = data
		}
	},
	actions: {
		asset({ commit }, data) {
			return new Promise((resolve, reject) => {
				let url = data.password
					? `public/assets/${data.asset_slug}?api_key=${process.env.VUE_APP_API_KEY}&password=${data.password}`
					: `public/assets/${data.asset_slug}?api_key=${process.env.VUE_APP_API_KEY}`
				Vue.axios.get(url)
				.then((response) => {
					commit('asset', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
		assets({ commit }, data) {
			return new Promise((resolve, reject) => {
				let url = data.password
					? `public/assets?api_key=${process.env.VUE_APP_API_KEY}&password=${data.password}`
					: `public/assets?api_key=${process.env.VUE_APP_API_KEY}`
				Vue.axios.get(url, {
					params: {
						category_id: data.category_id ? data.category_id : null,
						types: data.types ? data.types : null
					}
				})
				.then((response) => {
					commit('assets', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
		channel({ commit }) {
			return new Promise((resolve, reject) => {
				Vue.axios.get(`public/channel?api_key=${process.env.VUE_APP_API_KEY}`)
				.then((response) => {
					commit('channel', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
		categories({ commit }, data) {
			return new Promise((resolve, reject) => {
				let url =  data.password
					? `public/categories?api_key=${process.env.VUE_APP_API_KEY}&password=${data.password}`
					: `public/categories?api_key=${process.env.VUE_APP_API_KEY}`
				Vue.axios.get(url, {
					params: {
						category_id: data.category_id ? data.category_id : null
					}
				})
				.then((response) => {
					commit('categories', response.data.categories.categories)
					resolve(response.data.categories.categories)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
		category({ commit }, data) {
			return new Promise((resolve, reject) => {
				let url =  data.password
					? `public/categories/${data.category_slug}?api_key=${process.env.VUE_APP_API_KEY}&password=${data.password}`
					: `public/categories/${data.category_slug}?api_key=${process.env.VUE_APP_API_KEY}`
				Vue.axios.get(url)
				.then((response) => {
					if(!this.state.rootCategory) {
						commit('rootCategory', response.data)
					}
					commit('category', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		},
		download({commit}, data) {
			return new Promise((resolve, reject) => {
				Vue.axios.get(`public/downloads/video/${data.hash}?api_key=${process.env.VUE_APP_API_KEY}`)
				.then((response) => {
					commit('downloading', 0)
					resolve(response)
				})
				.catch((error) => {
					commit('downloading', null)
					reject(error)
				})
			})
		},
		downloadFromUrl({commit}, data) {
			return new Promise((resolve, reject) => {
				axios({ url: data.url, method: 'GET', responseType: 'blob',
					onDownloadProgress: (event) => {
						commit('downloading', Math.round((event.loaded / event.total)*100))
					}
				})
				.then((response) => {
					commit('downloading', null)
					resolve(response)
				})
				.catch((error) => {
					commit('downloading', null)
					reject(error)
				})
			})
		},
		loaded({commit}, data) {
			commit('loaded', data)
		},
		password({commit}, data) {
			commit('password', data)
			return Promise.resolve(data);
		},
		type({ commit }, slug) {
			return new Promise((resolve, reject) => {
				Vue.axios.get(`public/type/${slug}?api_key=${process.env.VUE_APP_API_KEY}`)
				.then((response) => {
					commit('type', response.data)
					resolve(response.data)
				})
				.catch((error) => {
					reject(error)
				})
			})
		}
	}
})
