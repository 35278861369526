import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)
const routes = [
	{
		path: '/asset/:slug',
		name: 'asset',
		component: () => import(/* webpackChunkName: "bundle" */ '../views/Asset.vue')
	},
	{
		path: '/category/:slug',
		name: 'category',
		component: () => import(/* webpackChunkName: "bundle" */ '../views/Category.vue')
	},
	{
		path: '/login/:slug',
		name: 'login',
		component: () => import(/* webpackChunkName: "bundle" */ '../views/Login.vue')
	},
	{
		path: '/notfound',
		name: 'notfound',
		component: () => import(/* webpackChunkName: "bundle" */ '../views/NotFound.vue')
	},
	{
		path: '/:a?/:b?/:c?/:d?/:e?/:f?/:g?/:h?/:i?/:slug',
		name: 'type',
		component: () => import(/* webpackChunkName: "bundle" */ '../views/Type.vue')	
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
